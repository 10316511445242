.palette__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}

.palette__color-name,
.palette__color-srgb {
  margin: 0;
  font-size: 16px;
  line-height: 1.25;
}


.palette__colors {
  display: flex;
  flex-wrap: wrap;
  margin: -9px -12px;
  padding: 0;
  list-style: none;
}
