.add-file {
  .input-wrapper {
    width: 100%;

    input {
      padding-right: 180px;
    }
  }
}

.add-file__wrapper {
  position: relative;
  display: flex;

  .button-clear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;

    &.active {
      display: block;
    }
  }
}

.add-file__input-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;

  label {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    p {
      margin: 0;
      margin-right: 6px;

      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.add-file__desc {
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: $color-grey-96;
}
