.table {
  position: relative;

  &--pattern {
    display: none;
  }

  [contenteditable][placeholder]:empty {
    &::before {
      content: attr(placeholder);
      position: static;
      color: $color-grey-96;
      background-color: transparent;

      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      pointer-events: none;
    }
  }

  table {
    width: max-content;
    border-collapse: collapse;
  }

  th {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 20px 12px;
    border: 1px solid $color-hawkes-blue;
    background-color: $color-solitude-2;
    text-align: left;
  }

  td {
    position: relative;
    vertical-align: top;

    font-weight: 500;
    padding: 20px 12px;
    border: 1px solid $color-hawkes-blue;
    font-size: 16px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    transition: border-color $default-transition-settings;
    align-self: start;

    &.active {
      .table__menu-list {
        display: block;
      }

      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      border: 1px solid $color-nero;
      opacity: 0;
      transition: opacity $default-transition-settings;
      pointer-events: none;
    }

    &.invalid {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100%);
        height: calc(100%);
        border: 1px solid $color-roman;
        opacity: 1;
        transition: opacity $default-transition-settings;
        pointer-events: none;
      }
    }

    &:focus {
      z-index: 1;
      outline: none;

      &::after {
        opacity: 1;
      }
    }
  }

  .table__menu {
    padding-right: 32px;
    cursor: pointer;
  }
}

.table__wrapper {
  overflow: auto;
}

.table__number {
  width: 96px;
  word-break: break-all;
}

.table__name {
  width: 152px;
  word-break: break-all;
}

.table__service {
  width: 362px;

  span {
    &:empty:not(:focus)::before {
      content: attr("placeholder");
    }

    display: block;
    min-height: 20px;
  }
}

.table__count {
  width: 76px;
  white-space: nowrap;
}

.table__price {
  width: 99px;
  white-space: nowrap;
}

.table__total {
  width: 98px;

  white-space: nowrap;
}

.table__dates {
  width: 135px;
}

.table__comment {
  width: 317px;
}

.table__dropdown {
  position: absolute;
  right: 12px;
  top: 27px;
  pointer-events: none;
}
