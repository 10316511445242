.table-menu {
  @include list-reset;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  padding: 24px;
  width: 235px;
  color: $color-default-black;

  font-size: 16px;
  line-height: 20px;

  transition: opacity $default-transition-settings, visibility $default-transition-settings;
  border: 1px solid $color-hawkes-blue;
  border-radius: 6px;
  background-color: $color-default-white;

  &.active {
    z-index: 2;
    visibility: visible;
    opacity: 1;
  }

  &.readonly {
    .table-menu__item {
      &--cut {
        pointer-events: none;
        color: $color-grey-96;
      }

      &--paste {
        pointer-events: none;
        color: $color-grey-96;
      }
    }
  }

  &.firefox {
    .table-menu__item {
      &[data-action="cut"],
      &[data-action="copy"],
      &[data-action="paste"] {
        display: none;
      }
    }
  }
}

.table-menu__item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;

  transition: color $default-transition-settings;

  &:hover {
    color: $color-cornflower-blue;
  }

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 6px;
  }
}
