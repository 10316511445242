.radio-button {
  label {
    display: flex;
    justify-content: space-between;

    padding: 14px 15px 13px 15px;
    width: 100%;
    border: 1px solid $color-solitude-3;
    border-radius: 6px;
    line-height: 20px;

    transition: border-color $default-transition-settings;
    cursor: pointer;

    span {
      display: block;
      margin: 0;
    }

    &:hover {
      border-color: $color-hawkes-blue;
    }
  }

  input {
    &:checked {
      & ~ label {
        border: 2px solid $color-cornflower-blue;
        padding: 13px 14px 12px;
      }
    }
  }
}

.radio-button__price {
  white-space: nowrap;
}
