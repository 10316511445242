.page-title {
  padding-bottom: 96px;
  padding-top: 87px;

  .container {
    display: flex;
    justify-content: space-between;
  }

  &--event {
    padding: 0;
  }
}

.page-title__title {
  margin: 0;
  margin-right: 40px;

  font-weight: bold;
  font-size: 48px;
  line-height: 64px;

  color: $color-black-primary;
}

.page-title__links {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-top: -8px;

  .btn {
    margin-left: 8px;
    margin-top: 8px;
  }
}

.page-title__wrapper {
  position: relative;
  padding-top: 58px;
  padding-bottom: 56px;
  width: 100%;

  .container {
    flex-direction: column;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    z-index: -1;
  }

  .page-title__title {
    color: $color-default-white;
    margin-bottom: 33px;
  }

  .btn {
    padding: 14px 32px 15px 33px;
    background-color: $color-default-white;
    transition: opacity $default-transition-settings;
  }
}

.page-title__time {
  margin-bottom: -3px;

  font-size: 20px;
  line-height: 28px;

  color: $color-default-white;
}

.page-title__buttons-wrapper {
  display: flex;
  flex-shrink: 0;
  padding-top: 4px;
  align-self: start;

  .btn {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
