.order-details {
  padding-top: 32px;
  padding-right: 32px;
  padding-bottom: 14px;
}

.order-details__title {
  margin: 0;
  padding: 48px 0 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
  color: $color-grey-chateau;
}

.order-details__group-title {
  margin: 0;
  padding: 48px 0 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  color: $color-grey-chateau;
  border-bottom: 1px solid $color-solitude-3;
}

.order-details__option {
  display: flex;
  align-items: flex-start;
  padding: 21px 0;
  min-height: 100px;
  border-bottom: 1px solid $color-solitude-3;
}

.order-details__quantity-controls {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  border-radius: 6px;

  & > button {
    flex-shrink: 0;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  & > a {
    margin-right: 8px;
    font-size: 14px;
    line-height: 14px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.order-details__quantity-reset {
  &--hide {
    display: none;
  }
}

.order-details__option-price {
  flex-shrink: 0;
  width: 170px;
  text-align: right;
  font-weight: 500;
}

.order-details__total-price {
  margin: 0;
  font-size: 20px;
  line-height: 1.4;
}

.order-details__unit-price {
  margin: 0;
  font-size: 20px;
  line-height: 1.4;
  color: $color-grey-chateau;
}
