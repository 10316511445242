.enter {
  position: relative;
  padding: 120px 0;
}

.enter__bg {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  z-index: -1;
}

.enter__row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 88px 0 114px;

  @include viewport--lg {
    padding: 0;
  }
}

.enter__info {
  color: $color-default-white;
  max-width: 554px;
  margin-right: 50px;
  margin-top: 27px;
}

.enter__date {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 15px;
}

.enter__title {
  margin: 0 0 41px;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

.enter__description {
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
}

.enter__form {
  max-width: 502px;
  min-width: 400px;
  width: 100%;
}
