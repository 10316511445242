.dropdown {
  @include list-reset;
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: -10px;
  transform: translateY(100%) translateX(-50%);
  width: 267px;
  padding: 29px 24px;

  visibility: hidden;
  border: 1px solid $color-hawkes-blue;
  border-radius: 6px;
  background-color: $color-default-white;
  opacity: 0;
  transition: opacity $default-transition-settings, visibility $default-transition-settings;

  li {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $color-night-rider;
    transition: opacity $default-transition-settings;

    &:hover {
      opacity: 0.7;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.dropdown--language {
    width: auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;

    width: 16px;
    height: 6px;
    transform: translateX(-50%) translateY(-100%);

    background-image: url("../img/svg/wave.svg");
  }
}
