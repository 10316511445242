.checkbox-wrapper {
  width: 100%;
  position: relative;

  label {
    display: flex;
    padding-bottom: 14px;
    padding-top: 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: -32px;

    color: $color-grey;

    transition: color $default-transition-settings;
    cursor: pointer;

    & > span {
      position: relative;
      display: block;
      width: calc(50% - 32px);
      margin-left: 32px;
      transition: color $default-transition-settings;
      padding-left: 32px;

      &::before {
        content: "";
        position: absolute;
        left: 0;

        width: 20px;
        height: 20px;

        border: 1px solid $color-hawkes-blue;
        border-radius: 3px;
      }

      svg {
        position: absolute;
        opacity: 0;
        left: 0;

        transition: opacity $default-transition-settings;
      }
    }

    @include hover {
      span {
        color: $color-black-primary;
      }
    }
  }

  input {
    &:checked {
      & ~ label {
        svg {
          opacity: 1;
        }
      }
    }
  }
}
