.load-input {
  input {
    display: none;
  }

  label {
    height: 60px;
  }

  &.is-progress {
    pointer-events: none;

    .load-input__percent {
      display: block;
    }

    label {
      justify-content: flex-start;
      background-color: transparent;
      border: 1px solid $color-solitude-3;
    }

    .load-input__text {
      width: 100%;
      padding-right: 32px;
    }

    .load-input__title {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.is-success {
    .load-input__text {
      width: 100%;
      padding-right: 32px;
    }

    .load-input__title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .load-input__label-reset {
      display: flex;
    }

    label {
      justify-content: flex-start;
    }
  }

  &.is-size-error {
    .load-input__errors {
      display: block;

      .load-input__label-size-error {
        display: block;
      }
    }
  }
}

.load-input__wrapper {
  position: relative;
}

.load-input__error {
  display: none;
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  color: $color-roman;

  &.visible {
    display: block;
  }
}

.load-input__percent {
  position: absolute;
  display: none;
  z-index: 2;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 20px;
}

.load-input__text {
  z-index: 1;
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
}

.load-input__label-reset {
  display: none;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 2;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity $default-transition-settings;

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-right: 0;
    fill: $color-roman;
  }
}

.load-input__progress {
  position: absolute;
  pointer-events: none;
  border-radius: 6px;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: transparent;
  appearance: none;
  overflow: hidden;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: $color-solitude-3;
  }

  &::-moz-progress-bar {
    background-color: $color-solitude-3;
  }
}


.load-input__errors {
  display: none;

  span {
    display: none;
  }
}
