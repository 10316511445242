.custom-input {
  display: flex;
  align-items: center;

  label {
    display: block;
    margin-right: 24px;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.11;
    color: $color-black-primary;
  }

  input {
    display: block;
    width: 195px;
    min-height: 34px;
    padding: 16px 50px 16px 24px;

    font-weight: 700;
    font-size: 36px;
    line-height: 1.11;
    color: $color-black-primary;

    background-color: $color-default-white;
    border-radius: 8px;

    transition: color $default-transition-settings, border $default-transition-settings;

    outline: none;

    &::placeholder {
      color: $color-black-primary;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__error {
    display: none;
    margin: 0 0 8px;

    font-size: 14px;
    line-height: 18px;
    color: #ff0000;
  }

  &--select {
    .custom-input__dropdown-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 23px;

      transition: fill 0.3s ease, transform 0.3s ease;

      pointer-events: none;

      fill: #000000;
    }

    input {
      border: 1px solid $color-hawkes-blue;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $color-solitude-2;
      }
    }

    .custom-input__select-list {
      position: absolute;
      top: 100%;
      left: 0;

      width: 100%;
      margin: 0;
      padding: 16px 0;
      overflow-y: auto;

      list-style: none;

      background-color: $color-default-white;
      visibility: hidden;
      border: 1px solid $color-hawkes-blue;
      border-top: none;
      border-radius: 0 0 8px 8px;
      opacity: 0;
      z-index: 20;

      transition: visibility 0.3s ease, opacity 0.3s ease;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .custom-input__select-item {
      position: relative;
      margin: 0;
      padding: 9px 24px;

      font-weight: 400;
      font-size: 20px;
      line-height: 1.4;
      color: $color-black-primary;
      background-color: $color-default-white;

      cursor: pointer;

      transition: $default-transition-settings;

      outline: none;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 0.6;
        }
      }

      &--selected {
        font-weight: 700;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 10px;
          width: 8px;
          height: 8px;
          transform: translateY(-50%);
          border-radius: 50%;
          background-color: #000000;
        }
      }
    }
  }

  &.active {
    input {
      border-radius: 8px 8px 0 0;

      &:hover,
      &:focus {
        background-color: $color-default-white;
        border: 1px solid $color-hawkes-blue;
      }
    }

    .custom-input__select-list {
      visibility: visible;
      opacity: 1;
    }

    .custom-input__dropdown-icon {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &--event-card-popup {
    input {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.25;
      background-color: $color-solitude-2;
      border: none;
      border-radius: 6px;
    }

    &.active {
      input {
        background-color: $color-solitude-2;
        border: none;
        border-radius: 6px 6px 0 0;

        &:hover,
        &:focus {
          background-color: $color-solitude-2;
          border: none;
        }
      }
    }

    .custom-input__select-list {
      top: 53px;
      width: calc(100% + 2px);
      margin: 0 -1px;
      padding: 18px 24px;

      list-style: none;

      background-color: $color-default-white;
      visibility: hidden;
      border: 1px solid $color-hawkes-blue;
      border-top: none;
      border-radius: 0 0 7px 7px;
      opacity: 0;
      z-index: 100;

      transition: visibility 0.3s ease, opacity 0.3s ease;
    }

    .custom-input__select-item {
      position: relative;
      margin: 0;
      padding: 6px 0;
      font-size: 16px;
      line-height: 1.25;
    }
  }
}
