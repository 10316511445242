@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "PT-Root-UI";

  font-display: swap;
  src:
    url("../fonts/PT-Root-UI_Regular.woff2") format("woff2"),
    url("../fonts/PT-Root-UI_Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "PT-Root-UI";

  font-display: swap;
  src:
    url("../fonts/PT-Root-UI_Medium.woff2") format("woff2"),
    url("../fonts/PT-Root-UI_Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "PT-Root-UI";

  font-display: swap;
  src:
    url("../fonts/PT-Root-UI_Bold.woff2") format("woff2"),
    url("../fonts/PT-Root-UI_Bold.woff") format("woff");
}
