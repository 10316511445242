.counter-list {
  counter-reset: counterList;

  list-style: none;
  padding: 0;
  margin: 0;
}

.counter-item {
  margin: 0;
  color: $color-grey-96;
  transition: color $default-transition-settings;

  &.active {
    color: $color-black-primary;
  }

  &::before {
    counter-increment: counterList;
    content: counter(counterList) ". ";
  }
}
