.loaded-files-list {
  @include list-reset;
  display: block;
  margin-top: 45px;
  max-height: 258px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 21px;
    padding-bottom: 24px;

    border-top: 1px solid $color-solitude-2;

    &:first-child {
      border-top: 0;
      padding-top: 0;
    }
  }

  button {
    width: 15px;
    height: 15px;
    border: 0;
    background-color: transparent;
    padding: 0;

    cursor: pointer;

    @include focus {
      outline: none;
    }

    svg {
      transition: fill $default-transition-settings;
    }

    &:hover {
      svg {
        fill: $color-black-primary;
      }
    }
  }

  p {
    margin: 0;
  }

  span {
    color: $color-grey-96;
  }
}
