.category-cards {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin-right: -32px;
  margin-top: -32px;

  .category-card {
    width: calc(100% / 3 - 32px);
    margin-right: 32px;
    margin-top: 32px;
  }

  @media (max-width: 1160px) {
    .category-card {
      width: calc(50% - 32px);
    }
  }
}

.category-card__title {
  margin: 0;
  margin-bottom: auto;
  padding-right: 32px;
  text-align: left;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.category-card__quantity {
  margin: 0;
  margin-bottom: auto;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "pnum" on, "lnum" on;

  color: $color-grey-96;
}

.category-card__security {
  padding: 9px 16px;
  border: 0;
  background-color: $color-nero;
  border-radius: 4px;
  color: $color-default-white;

  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  transition: background-color $default-transition-settings;
  cursor: pointer;

  @include hover {
    background-color: $color-dark-slate;
  }

  @include focus {
    outline: none;
  }
}

.category-card__button {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: 24px 10px 17px 20px;

  border: 0;
  background-color: $color-default-white;
  border-radius: 6px;

  @include hover {
    cursor: pointer;
  }

  @include focus {
    outline: none;
  }
}

.category-card {
  position: relative;
  padding: 24px 10px 17px 20px;
  display: flex;
  flex-direction: column;
  min-height: 160px;

  background-color: $color-default-white;
  border-radius: 6px;

  .order-counter {
    position: absolute;
    right: 10px;
    bottom: 11px;
  }

  &--button {
    padding: 0;
  }

  &--security {
    .category-card__title {
      margin-bottom: 6px;
    }
  }

  &--product {
    min-height: 470px;
    padding: 0;

    .category-card__title {
      margin-bottom: 7px;
    }

    .category-card__text-wrapper {
      display: flex;
      flex-direction: column;
      padding: 25px 10px 17px 20px;
      flex-grow: 1;
    }

    .category-card__footer {
      margin-top: 11px;
    }

    .category-card__desc {
      margin: 0;
      margin-bottom: auto;
      color: $color-grey-96;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }

    .category-card__img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 309px;
      border-bottom: 1px solid $color-solitude-3;
      border-radius: 6px 6px 0 0;
      overflow: hidden;

      &--blur {
        &::before {
          content: "";
          z-index: 1;
          position: absolute;
          left: 0;
          top: 0;

          width: 100%;
          height: 100%;

          background: rgba(255, 255, 255, 0.1);

          backdrop-filter: blur(40px);
        }
      }

      img {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 309px;
        object-fit: contain;
      }

      .category-card__img-background {
        z-index: 0;
        object-fit: cover;
      }
    }
  }
}

.category-card__footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-card__price {
  white-space: nowrap;
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}
