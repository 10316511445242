.content-block {
  h2 {
    margin-top: 0;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
  }

  h3 {
    margin-bottom: 19px;
    font-weight: 500;
    margin-top: 67px;

    font-size: 20px;
    line-height: 24px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  a {
    @include reset-btn;
    text-align: left;
    color: $color-cornflower-blue;
    transition: color $default-transition-settings;
    font-weight: 500;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: #2566e8;
      }
    }
  }

  p {
    margin-top: 19px;
    margin-bottom: 20px;

    font-size: 16px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    margin-top: 43px;
    margin-bottom: 48px;
  }

  figure {
    margin: 0;
    margin-top: 43px;
    margin-bottom: 48px;

    img {
      margin: 0;
      margin-bottom: 9px;
    }
  }

  figcaption {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: $color-grey-chateau;
  }

  ul {
    padding: 0;
    margin-top: 19px;
    margin-bottom: 20px;
    list-style: none;

    li {
      display: flex;

      margin-top: 19px;
      margin-bottom: 20px;

      font-size: 16px;
      line-height: 20px;

      p {
        margin: 0;

        &:first-child {
          margin-right: 4px;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn {
    margin-top: 24px;
    margin-bottom: 40px;

    align-items: center;
    padding: 19px 32px 20px 24px;
    width: fit-content;
    overflow: hidden;
    font-size: 16px;
    line-height: 20px;
    border: 0;
    color: #1c1c1c;
    background-color: #f0f2f5;
    border-radius: 6px;
    transition: background-color $default-transition-settings;
    cursor: pointer;
    font-weight: 400;

    &:hover {
      color: inherit;
    }

    &:focus {
      color: inherit;
    }
  }
}
