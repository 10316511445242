.order-option {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  margin-right: 32px;
}

.order-option__thumb {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  width: 50px;
  height: 50px;
  margin-left: 17px;
  margin-right: 33px;
}

.order-option__desc {
  margin: 6px 0;
}

.order-option__service-name {
  margin: 0;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
}

.order-option__service-details {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;
  color: $color-grey-chateau;
}
