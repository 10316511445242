@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      @content;
    }
  }
}

@mixin focus {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:focus {
      @content;
    }
  }
}

// Desktop first

@mixin viewport--xxl {
  @media (max-width: $viewport--xxxl - 1) {
    @content;
  }
}

@mixin viewport--xl {
  @media (max-width: $viewport--xxl - 1) {
    @content;
  }
}

@mixin viewport--lg {
  @media (max-width: $viewport--xl - 1) {
    @content;
  }
}

@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin reset-btn {
  padding: 0;
  margin: 0;
  background: none;
  color: inherit;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
