.success-order {
  padding: 90px 0;
}

.success-order__wrapper {
  width: 672px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 42px;

    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
  }

  p {
    font-size: 20px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 30px;
    color: $color-grey-chateau;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-top: 73px;
  }
}
