.tab__item {
  transition: opacity $default-transition-settings;

  &.hide {
    display: none;
  }

  &.no-opacity {
    opacity: 0;
  }
}
