
.modal-footer {
  display: flex;
  border-top: 1px solid $color-hawkes-blue;
}

.modal-footer__price-wrapper {
  padding: 13px 32px 15px 32px;
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid $color-hawkes-blue;
  font-weight: 500;
}

.modal-footer__price {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
}

.modal-footer__desc {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: $color-grey-96;
}

.modal-footer__add {
  width: 50%;
  border-radius: 0 0 6px 0;
}
