.stand__details {
  padding: 48px 0;
  background-color: $color-solitude-2;
}

.stand__menu {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
  margin-bottom: 8px;
  padding: 0;
  list-style: none;

  li {
    width: 25%;
    min-width: max-content;
    padding: 8px;
  }
}

.stand__menu-link {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  font-weight: 500;
  color: $color-black-primary;
  background-color: $color-default-white;
  border-radius: 6px;
  transition: opacity $default-transition-settings;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  svg {
    margin-right: 6px;
  }
}
