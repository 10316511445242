.modal-price-list {
  padding: 48px 32px;
}

.modal-price-list__header {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.modal-price-list__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $color-grey-chateau;
  margin-top: 0;
  margin-bottom: 28px;
}

.modal-price-list__list {
  padding: 0;
  padding-top: 24px;
  margin: 0;
  list-style: none;
  border-top: 1px solid $color-solitude-3;
}

.modal-price-list__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  &--header {
    color: $color-grey-chateau;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.modal-price-list__service {
  max-width: 600px;
  margin: 0;
}

.modal-price-list__price {
  margin: 0;
}
