.text-content-tabs {
  position: relative;
  width: 25.7%;
  min-width: 300px;
  font-size: 16px;
  line-height: 20px;
  color: $color-black-primary;

  border-right: 1px solid $color-solitude-3;

  .counter-list {
    a {
      font-weight: 500;
    }

    .counter-item {
      &::before {
        font-weight: 500;
      }
    }
  }

  .text-content-tabs__content {
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    margin-top: 12px;

    list-style: none;

    transition: max-height $default-transition-settings;

    a {
      font-weight: normal;
      display: block;
      padding: 4px 25px 6px 25px;
    }

    li {
      margin-bottom: 2px;
      border-radius: 4px;

      transition: background-color $default-transition-settings;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        background-color: $color-solitude-2;
      }

      @include hover {
        background-color: $color-solitude-2;
      }
    }
  }
}


.text-content-tabs__tab-list {
  top: 0;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
  max-height: calc(100vh - 54px);
  overflow-x: hidden;
  overflow-y: auto;
}

.text-content-tabs__tab-item {
  transition: opacity $default-transition-settings;

  &.hide {
    display: none;
  }

  &.no-opacity {
    opacity: 0;
  }
}

.text-content-tabs__item {
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 16px;

  &.active {
    .text-content-tabs__btn {
      color: $color-black-primary;
    }
  }

  .text-content-tabs__content {
    height: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.text-content-tabs__controls {
  position: relative;
  display: flex;
  margin-right: -34px;

  .tab__control {
    position: relative;
    margin: 0;
    margin-right: 34px;
    padding-top: 16px;
    padding-bottom: 18px;
    cursor: pointer;

    font-weight: 500;

    &.active {
      &::before {
        content: "";
        position: absolute;

        left: 0;
        bottom: 0;

        width: 100%;
        height: 1px;
        background-color: $color-default-black;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: -32px;
    bottom: 0;

    width: 100%;
    height: 1px;
    background-color: $color-solitude-3;
  }
}

.text-content-tabs__btn {
  display: block;
  margin: 0;
  color: $color-grey-96;
  transition: color $default-transition-settings;

  @include hover {
    color: $color-black-primary;
  }
}
