.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: rgba($color-default-black, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &--inner-scroll {
    overflow-y: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    .modal--inner-scroll & {
      max-height: 100%;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 640px;
    border-radius: 6px;
    overflow: hidden;
    background-color: $color-default-white;
    font-weight: 500;
  }

  &__content-inner {
    .visually-hidden {
      left: 0;
      top: 0;
    }

    .modal--inner-scroll & {
      max-height: calc(100vh - 120px);
      max-height: calc(100 * var(--vh, 1vh) - 120px);
      overflow-y: auto;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    outline: none;

    svg {
      display: block;
      width: 16px;
      height: 16px;
      fill: $color-default-black;
      transition: fill $default-transition-settings;
    }

    &::after {
      content: "";
      position: absolute;
      top: -8px;
      right: -8px;
      width: 32px;
      height: 32px;
    }

    &:hover,
    &:focus {
      svg {
        fill: $color-grey;
      }
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    display: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &--upload {
    .loaded-files-list {
      display: none;
    }

    .modal__action-btn {
      display: none;
      border-top: 1px solid $color-hawkes-blue;
      border-radius: 0 0 6px 6px;
    }
  }

  &--upload-active {
    .loaded-files-list {
      display: block;
    }

    .file-upload-field {
      label {
        height: 306px;
        margin-top: 1px;
      }
    }

    .modal__action-btn {
      display: block;
    }
  }

  &--notification {
    .modal__content {
      width: 590px;
    }

    .documents-upload {
      padding: 32px;
      width: 100%;
    }

    .documents-upload__header {
      margin-bottom: 18px;
    }

    button {
      margin-top: 0;
    }
  }

  &--delete-badges {
    .modal__content {
      width: 590px;
    }

    .documents-upload {
      padding: 32px;
      width: 100%;
    }

    .documents-upload__header {
      margin-bottom: 18px;
    }

    button {
      margin-top: 0;
    }
  }

  &--logo {
    .modal__content {
      width: 752px;
    }

    .modal-sidebar__wrapper {
      max-height: calc(100vh - 302px);
    }
  }

  &--order {
    .modal__content {
      width: 784px;
    }

    .modal-form__content {
      padding-bottom: 0;
    }
  }

  &--badges {
    .modal__content {
      width: 736px;
    }
  }

  &--badges-edit {
    .modal__content {
      width: 736px;
    }
  }

  &--badges-check {
    .modal__content {
      width: 736px;
    }
  }

  &--add-contractor {
    .modal__content {
      width: 594px;
    }
  }

  &--price-list {
    .modal__content {
      width: 977px;
      min-height: 518px;
    }
  }

  &--tariffs-increase {
    .modal__content {
      width: 380px;
    }
  }

  &--upgrade-stand {
    .modal__content {
      width: 594px;
    }
  }

  &--cart {
    .modal__content {
      width: 500px;
    }
  }

  &--edit-info {
    .modal__content {
      width: 512px;
    }
  }

  &--change-parameters {
    .modal__content {
      width: 320px;
    }
  }

  &--create-chat {
    .modal__content {
      width: 512px;
    }
  }

  &--lamination {
    .modal__content {
      width: 512px;
    }
  }

  &--pwd-recover {
    .modal__content {
      max-width: 502px;
    }
  }

  &--inscription {
    .modal__content {
      width: 512px;
    }
  }

  &--inscription-colors {
    .modal__content {
      width: 512px;
    }
  }
}

.modal__title {
  margin: 0;
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 24px;
}

.modal__header {
  padding: 32px 32px 0 32px;
}

.modal__container {
  padding: 0 32px;
}

.modal__desc {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: $color-grey-96;
}
