.badges-list {
  @include list-reset;

  .add-item {
    margin-top: 14px;
    margin-bottom: 49px;
  }
}

.badges-item {
  label {
    position: relative;
    display: flex;
    padding-top: 16px;
    padding-bottom: 19px;

    border-bottom: 1px solid $color-hawkes-blue;

    &:hover {
      .badges-item__buttons {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  input {
    display: none;
  }
}

.badges-item__text-wrapper {
  margin-right: auto;
  width: 260px;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: $color-grey-chateau;

  span {
    display: block;
  }
}

.badges-item__price {
  margin-left: 20px;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: $color-green;

  &--payment {
    color: $color-black-primary;
  }
}

.badges-item__buttons {
  position: absolute;
  right: 0;
  bottom: 19px;
  display: flex;

  visibility: hidden;
  opacity: 0;

  transition: visibility $default-transition-settings, opacity $default-transition-settings;

  a {
    margin-left: 12px;
  }
}

.badges-item__name {
  width: 200px;
  padding-right: 100px;
  display: block;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
