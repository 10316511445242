.error-404 {
  padding: 90px 0;
  color: $color-black-primary;
  border-top: 1px solid $color-solitude-3;

  p {
    max-width: 670px;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    color: $color-grey-chateau;

    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
}

.error-404__title {
  margin: 0;
  margin-bottom: 42px;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.17;
}
