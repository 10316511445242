.cart-card {
  display: flex;
  align-items: flex-start;
}

.cart-card__thumb {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  width: 56px;
  height: 56px;
  margin-right: 12px;
}

.cart-card__desc {
  margin: 6px 0;
}

.cart-card__service-name {
  margin: 0;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}

.cart-card__prices {
  display: flex;
  flex-wrap: wrap;
}

.cart-card__total-price {
  margin: 0;
  font-size: 16px;
  line-height: 1.25;
}

.cart-card__price-per-unit {
  margin: 0;
  margin-left: 12px;
  font-size: 16px;
  line-height: 1.25;
  color: $color-grey-chateau;
}
