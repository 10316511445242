.order-counter {
  overflow: hidden;
  border-radius: 4px;

  form {
    position: relative;
    display: flex;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 32px;
      height: 32px;

      border: 0;
      border-radius: 4px;
      background-color: $color-black-primary;
      padding: 0;

      cursor: pointer;

      @include focus {
        outline: none;
      }

      &:hover {
        background-color: $color-dark-slate;
      }
    }

    .order-counter__counter {
      position: relative;
      left: 105px;
      transition: left $default-transition-settings;
    }

    .order-counter__minus {
      position: relative;
      left: 105px;
      border-radius: 4px 0 0 4px;
      transition: left $default-transition-settings, background-color $default-transition-settings;
    }

    .order-counter__plus {
      z-index: 1;
      transition: border-radius $default-transition-settings, background-color $default-transition-settings;

      span {
        display: none;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: $color-default-white;
      }

      &.configure {
        width: auto;
        padding: 0 16px;

        svg {
          display: none;
        }

        span {
          display: inline;
        }
      }
    }
  }

  &.is-active {
    .order-counter__counter {
      left: 0;
    }

    .order-counter__minus {
      left: 0;

      border-radius: 4px 0 0 4px;
    }

    .order-counter__plus {
      border-radius: 0 4px 4px 0;
    }
  }

  &--light {
    form {
      button {
        background-color: $color-solitude-2;
      }

      svg {
        fill: $color-grey-chateau;
      }
    }
  }
}

.order-counter__counter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 5px;
  min-width: 37px;
  width: 37px;
  height: 100%;
  background-color: $color-black-primary;
  border: 0;
  text-align: center;

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  color: $color-default-white;

  @include focus {
    outline: none;
  }

  .order-counter--light & {
    color: $color-black-primary;
    background-color: $color-solitude-2;
  }
}
