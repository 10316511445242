.table-contextmenu {
  @include list-reset;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 364px;
  padding: 24px;

  visibility: hidden;
  opacity: 0;
  overflow: auto;
  max-height: 350px;

  border: 1px solid $color-hawkes-blue;
  box-sizing: border-box;
  box-shadow: 0 3px 9px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
  background-color: $color-default-white;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &--dates {
    width: 181px;

    .checkbox-wrapper label span {
      width: 100%;
    }
  }

  li {
    margin-bottom: 12px;
    cursor: pointer;
    font-weight: 500;


    .checkbox-wrapper label {
      padding: 0;
      color: $color-black-primary;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
