.file-upload-field {
  p {
    margin: 0;
    text-align: center;

    font-size: 14px;
    line-height: 18px;
  }

  label {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 665px;
    border-radius: 6px;

    @media (max-height: 850px) {
      height: 465px;
    }

    &.highlight {
      background-color: $color-solitude-2;
    }

    cursor: pointer;
    transition: background-color $default-transition-settings;

    @include hover {
      background-color: $color-solitude-2;
    }
  }
}

.file-upload-field__choose {
  display: block;
  color: $color-black-primary;
}

.file-upload-field__drag {
  display: block;
  color: $color-grey-chateau;
}
