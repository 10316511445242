.search-form {
  label {
    position: relative;

    svg {
      content: "";
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
    }
  }

  input {
    width: 415px;
    padding: 13px 15px 14px;
    padding-left: 42px;
    font-size: 16px;
    line-height: 1.25;
    color: inherit;
    border: 1px solid $color-hawkes-blue;
    border-radius: 6px;
    outline: none;
    transition: border-color $default-transition-settings;

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 1000px $color-default-white;
      -webkit-text-fill-color: $color-black-primary;
    }

    &:hover,
    &:focus {
      border-color: $color-default-black;
    }
  }

  &--wide {
    input {
      width: 100%;
    }
  }

  &--gray {
    input {
      background-color: $color-solitude-2;
      border-color: $color-solitude-2;

      &:-webkit-autofill {
        box-shadow: inset 0 0 0 1000px $color-solitude-2;
        -webkit-text-fill-color: $color-black-primary;
      }
    }
  }

  &--white {
    input {
      height: 50px;
      padding: 0 40px;
      border: 1px solid white;
      background-color: $color-default-white;
      color: $color-black-primary;
      transition: border-color $default-transition-settings;

      &:hover,
      &:focus {
        padding: 0 40px;
        border: 1px solid $color-black-primary;
        border-color: $color-black-primary;
      }
    }
  }
}
