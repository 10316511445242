.service-document {
  padding-top: 60px;

  &--1 {
    .container {
      max-width: 1055px;
      padding: 0 40px;
    }

    .service-document__link {
      margin-top: 45px;
      margin-bottom: 32px;
    }

    .service-document__signature-item {
      h2 {
        margin-top: 0;
        margin-bottom: 38px;
      }
    }
  }

  &--2 {
    .container {
      max-width: 1493px;
      padding: 0 40px;
    }

    .service-document__number {
      margin-bottom: 30px;
    }

    .service-document__header {
      width: 482px;
      margin-right: auto;
      margin-bottom: 0;
    }

    .service-document__item {
      p {
        &:last-child {
          opacity: 0.6;
        }
      }
    }

    .service-document__table-wrapper {
      width: 100%;
      margin-bottom: 85px;
    }

    .service-document__signature-item {
      display: flex;
    }

    .service-document__signature-wrapper {
      margin-right: 20px;

      h2 {
        margin: 0;
        margin-bottom: 63px;
      }
    }

    .service-document__seal {
      position: relative;
    }

    .service-document__seal-circle {
      margin-right: 0;
    }

    .service-document__seal-text {
      position: absolute;
      left: 50%;
      bottom: 13px;
      text-align: center;
      width: auto;
      margin: 0;

      transform: translateX(-50%);
    }

    .service-document__link {
      margin-top: 68px;
      margin-bottom: 24px;
    }

    .service-document__signature-input {
      &--date {
        width: 124px;
      }

      &--signature {
        flex-grow: 1;
      }
    }
  }

  &--3 {
    padding-bottom: 60px;

    .service-document__table {
      th,
      td {
        &:nth-child(1) {
          width: 8%;
        }

        &:nth-child(2) {
          width: 13%;
        }

        &:nth-child(3) {
          width: 30%;
        }

        &:nth-child(4) {
          width: 6.3%;
        }

        &:nth-child(5) {
          width: 6.3%;
        }
      }

      .service-document__table-total {
        width: 6.3%;
      }

      .service-document__table-dates {
        width: 10.1%;
      }

      .service-document__table-comment {
        width: 20%;
      }

      .service-document__table-footer {
        th,
        td {
          width: auto;
        }
      }
    }

    .service-document__link {
      margin-top: 5px;
      margin-right: 0;
      margin-left: auto;
    }

    .service-document__number {
      margin-bottom: 0;
    }

    .service-document__header {
      width: auto;
      padding-right: 20px;
      margin-bottom: 40px;
    }

    .service-document__header-wrapper {
      margin-bottom: 60px;
    }

    .service-document__list {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 0;
    }
  }

  &--4 {
    padding-bottom: 80px;

    .service-document__link {
      margin-left: auto;
      margin-top: 5px;
    }

    .service-document__item {
      grid-template-columns: 226px 293px;
      column-gap: 60px;
    }


    .service-document__inputs {
      width: 409px;
      margin-bottom: 0;
      margin-right: 20px;
    }

    .service-document__signature-grid {
      display: flex;
    }

    .service-document__seal-text {
      margin: 8px 0 0;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      width: auto;
    }

    .service-document__seal {
      display: block;
      align-self: flex-end;

      input {
        display: block;
        width: 100%;
        border: 0;
        border-bottom: 1px solid #dfe1e6;
      }
    }

    .service-document__signature-input {
      &--date {
        width: 123px;
      }

      &--signature {
        width: auto;
        flex-grow: 1;
      }
    }

    .service-document__signature-item {
      margin-bottom: 58px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .service-document__signature-item h2 {
      margin-bottom: 40px;
    }

    .service-document__item p:first-child {
      padding: 0;
    }

    .container {
      max-width: 1493px;
      padding: 0 40px;
    }

    .service-document__grid {
      display: flex;
    }

    .service-document__signature-list {
      display: block;
    }

    .service-document__list {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 88px;
    }

    .service-document__number {
      margin-bottom: 24px;
    }

    .service-document__grid-wrapper {
      width: 580px;
    }

    .service-document__table-wrapper {
      width: 768px;
      margin-left: 65px;
      margin-bottom: 0;
      align-self: start;
    }

    .service-document__table {
      font-size: 14px;
      line-height: 18px;

      th {
        font-size: 12px;
        line-height: 16px;
      }

      th,
      td {
        padding: 16px 10px;
      }
    }

    .service-document__item {
      p {
        &:last-child {
          opacity: 0.6;
        }
      }
    }

    .service-document__table td:nth-child(1),
    .service-document__table th:nth-child(1) {
      width: 13%;
    }

    .service-document__header {
      margin-bottom: 70px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.service-document__signature-item {
  h2 {
    margin-top: 0;

    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}

.service-document__header-wrapper {
  display: flex;
  margin-bottom: 70px;
}

.service-document__header {
  margin-bottom: 55px;
}

.service-document__number,
.service-document__title {
  margin: 0;
  font-size: 28px;
  line-height: 32px;
  color: $color-black-primary;
  font-weight: 500;
}

.service-document__list {
  list-style: none;
  margin: 0;
  padding: 0;

  margin-bottom: 65px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  max-width: 785px;

  display: grid;
  row-gap: 14px;
}

.service-document__item {
  display: grid;
  grid-template-columns: 1fr 1fr;

  p {
    margin: 0;

    &:first-child {
      padding-right: 58px;
    }
  }
}

.service-document__table {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  border-collapse: collapse;

  th {
    background-color: $color-solitude-2;
    border: 1px solid $color-hawkes-blue;
    text-align: left;
    font-weight: 500;
  }

  td {
    border: 1px solid $color-hawkes-blue;
  }

  th,
  td {
    padding: 20px 12px;

    &:nth-child(1) {
      width: 11%;
    }

    &:nth-child(2) {
      width: 21%;
    }

    &:nth-child(3) {
      width: 42%;
    }

    &:nth-child(4) {
      width: 10%;
    }

    &:nth-child(5) {
      width: 16%;
    }
  }
}

.service-document__table-wrapper {
  margin-bottom: 110px;
  border: 1px solid $color-hawkes-blue;
  border-radius: 6px;
  overflow: hidden;
  width: 975px;

  .service-document__table {
    margin: -1px;
    margin-right: -2px;
    width: calc(100% + 3px);
  }
}

.service-document__signature-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 40px;
}

.service-document__signature-input {
  p {
    margin: 0;
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  input {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid $color-hawkes-blue;
  }

  &--name {
    width: 100%;
    margin-bottom: 24px;
  }

  &--date {
    width: 167px;
    margin-right: 20px;
  }

  &--signature {
    width: 166px;
  }
}

.service-document__inputs {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 45px;
}

.service-document__seal {
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    line-height: 16px;

    color: $color-grey-96;
    font-weight: 500;
    width: 61px;
  }
}

.service-document__seal-circle {
  width: 229px;
  height: 229px;
  border-radius: 50%;
  background-image: url("../img/svg/ellipse.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
}


.service-document__link {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: $color-grey-chateau;
  text-align: right;
}

.service-document__phone {
  color: $color-black-primary;
}
