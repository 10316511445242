.modal-change-parameters__title {
  margin: 32px;
  margin-top: 48px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
}

.modal-change-parameters__inputs {
  padding: 0 32px 36px;
  border-bottom: 1px solid $color-hawkes-blue;
}

.modal-change-parameters__gabarites {
  display: flex;
}

.modal-change-parameters__input {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .modal-change-parameters__gabarites &:not(:last-of-type) {
    margin-right: 32px;
  }
}
