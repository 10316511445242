.header {
  top: 0;
  z-index: 2;
  position: fixed;
  width: 100%;
  display: flex;

  padding: 24px 0;
  background-color: $color-default-white;

  transition: transform $modal-transition-settings;
  border-bottom: 1px solid $color-solitude-3;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--border {
    padding-bottom: 23px;
  }

  &.hidden {
    transform: translateY(-100%);
  }
}

.header__logo {
  display: flex;

  width: 100px;
  height: 40px;
  flex-shrink: 0;
  align-items: center;
}
