.order-payment {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 470px;
  border-left: 1px solid $color-solitude-3;
}

.order-payment__details {
  overflow: auto;
  margin: 48px 32px auto;
  height: calc(100% - 164.2px);
}

.order-payment__record {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  &--basic {
    margin-bottom: 30px;
  }

  &--total {
    margin-top: 30px;
  }

  dt {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
  }

  dd {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
  }
}

.order-payment__politics {
  margin: 28px 32px;
  font-size: 14px;
  line-height: 1.29;
  color: $color-grey-chateau;
}
