.button-field-add {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 226px;

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  border: 0;
  background-color: $color-default-white;
  border-radius: 6px;
  transition: background-color $default-transition-settings;
  cursor: pointer;

  @include hover {
    background-color: $color-solitude-2;
  }

  @include focus {
    outline: none;
  }
}
