.account-messages {
  color: $color-black-primary;
}

.account-messages__control-blocks {
  padding: 48px 0;
  border-top: 1px solid $color-solitude-3;

  // узкий (тонкий, низкий) вариант блоков управления
  &--thin {
    padding: 16px 0;
  }

  &--no-border-top {
    border-top: none;
  }
}

.account-messages__control-block {
  display: none;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;

  &--shown {
    display: grid;
  }
}

.account-messages__control {
  max-width: 290px;

  &--change-mode {
    margin-right: 16px;
  }
}

.account-messages__search-form {
  width: 672px;
}

.account-messages__controls-wrapper {
  display: flex;
  flex-wrap: wrap;

  .account-messages__control {
    margin: 0 4px;
  }
}

.account-messages__inner {
  display: flex;
  align-items: center;
}

.account-messages__control svg {
  flex-shrink: 0;
}
