.account {
  color: $color-black-primary;
}

.account__data {
  padding: 85px 0 135px;
  border-top: 1px solid $color-solitude-3;

  &--corporate {
    padding: 70px 0 165px;
  }
}

.account__data-container {
  display: flex;

  @include viewport--lg {
    justify-content: space-between;
  }
}

.account__data-block {
  width: 320px;

  &--thin {
    width: 200px;
  }

  &:not(:last-of-type) {
    margin-right: 150px;
  }

  .account__data--personal & {
    margin-top: 10px;

    &--header {
      margin-top: 0;
    }
  }

  .account__data--corporate & {
    margin-top: 15px;

    &--header {
      margin-top: 0;
    }
  }

  @include viewport--lg {
    &:not(:last-of-type) {
      margin-right: 0;
    }
  }
}

.account__data-title {
  margin: 0;
  margin-bottom: 36px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;

  .account__data-block--header & {
    margin-bottom: 26px;
    font-weight: 500;
    font-size: 28px;
    line-height: 1.14;
  }
}

.account__controls {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-right: -12px;
  margin-bottom: -12px;
  padding: 0;
  list-style: none;
}

.account__control {
  padding-right: 12px;
  padding-bottom: 12px;
}

.account__data-list {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}

.account__data-key {
  color: $color-grey-96;
}

.account__data-value {
  margin: 0;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
