.tarifs-increase {
  display: none;

  &--opened {
    display: block;
  }
}

.tarifs-increase__overlay {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.tarifs-increase__popup {
  position: absolute;
  // top: 20px;
  // right: 95px;
  z-index: 2;
  width: 380px;
  padding: 22px 24px 28px;
  color: $color-night-rider;
  background: $color-default-white;
  border: 1px solid $color-hawkes-blue;
  border-radius: 6px;
}

.tarifs-increase__title {
  margin: 0;
  margin-bottom: 17px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  color: $color-default-black;
}

.tarifs-increase__content {
  margin-bottom: 12px;
}

.tarifs-increase__content-paragraph {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}

.tarifs-increase__desc {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}
