.events {
  background-color: $color-solitude-2;
}

.events__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  padding: 32px 0;
  list-style: none;
}

.events__item {
  display: flex;
  width: 25%;
  padding: 16px;

  @include viewport--lg {
    width: 33.3333%;
  }
}
