.stand-annotation {
  position: relative;
}

.stand-annotation__link {
  display: flex;
  align-items: center;
  padding: 26px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: $color-night-rider;

  border: 1px solid $color-hawkes-blue;
  border-radius: 8px;
  cursor: help;

  svg {
    margin-right: 6px;
  }
}
