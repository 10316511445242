.modal-logo {
  display: flex;
}

.modal-logo__wrapper {
  flex-grow: 1;
}

.modal-sidebar {
  display: flex;
  flex-direction: column;
  width: 241px;
  padding-top: 25px;
  border-right: 1px solid $color-hawkes-blue;
}

.modal-sidebar__wrapper {
  max-height: calc(100vh - 350px);
  overflow: auto;
}

.modal-sidebar__list {
  @include list-reset;
  font-weight: 500;
}

.modal-sidebar__item {
  padding: 14px 32px;
  border-bottom: 1px solid $color-hawkes-blue;
  cursor: pointer;
  transition: background-color $default-transition-settings, border-color $default-transition-settings;

  @include hover {
    background-color: $color-solitude-2;
    border-color: $color-solitude-2;
  }

  &.active {
    background-color: $color-solitude-2;
    border-color: $color-solitude-2;
  }

  &.inactive {
    padding: 21px 32px 23px 32px;
    color: $color-grey-96;
  }
}

.modal-sidebar__title {
  margin: 0;

  font-size: 20px;
  line-height: 28px;
}

.modal-sidebar__desc {
  margin: 0;

  font-size: 12px;
  line-height: 16px;
}

.modal-sidebar__add {
  padding: 22px 32px;
  width: 100%;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  color: $color-grey-96;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $color-hawkes-blue;
  cursor: pointer;
  transition: border-color $default-transition-settings, color $default-transition-settings, background-color $default-transition-settings;

  @include hover {
    color: $color-black-primary;
    background-color: $color-solitude-2;
    border-color: $color-solitude-2;
  }

  @include focus {
    outline: none;
  }
}

.modal-sidebar__price {
  padding: 22px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  border-top: 1px solid $color-hawkes-blue;

  p {
    margin: 0;
    font-weight: 500;
  }

  &--with-desc {
    padding: 14px 32px;
  }
}

.modal-form__content {
  overflow: auto;
  flex-grow: 1;
  padding-top: 0;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 63px;

  margin-top: 32px;
  margin-bottom: auto;
  height: calc(100vh - 325px);

  .add-file {
    margin-bottom: 23px;
  }
}

.modal-form {
  flex-grow: 1;

  form {
    display: flex;
    flex-direction: column;

    height: 100%;
  }
}

.modal-form__radio-wrapper {
  margin-bottom: 39px;

  &:last-child {
    margin-bottom: 0;
  }

  ul {
    @include list-reset;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.modal-form__wrapper {
  margin-top: 0;
  margin-bottom: 39px;
}

.modal-form__radio-title {
  margin-top: 0;
  margin-bottom: 15px;
}

.modal-form__total {
  display: flex;
  justify-content: space-between;
  padding: 13px 47px 11px 47px;

  background-color: $color-solitude-2;

  p {
    margin: 0;
  }
}

.modal-form__buttons {
  display: flex;
  border-top: 1px solid $color-hawkes-blue;

  .modal-form__submit {
    border-radius: 0;
  }

  .modal-form__remove {
    border-radius: 0 0 6px 0;
  }
}

.modal-form__submit {
  padding-top: 21px;
  border-radius: 0 0 6px 0;
}

.modal-form__remove {
  padding: 0;
  flex-shrink: 0;
  width: 74px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid $color-hawkes-blue;

  svg {
    fill: $color-roman;
  }
}

.modal-sidebar__price-desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: $color-grey-96;
}

.modal-form__select-wrapper {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 23px;

  border-bottom: 1px solid $color-hawkes-blue;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal-form__select-list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-top: -16px;

  .modal-form__select-item {
    margin-left: 4px;
    margin-top: 16px;
    width: 124px;
  }
}

.modal-form__select-remove {
  background-color: transparent;
  border: 0;
  padding: 8px 10px;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  cursor: pointer;
  color: $color-grey-96;
  transition: color $default-transition-settings;

  @include hover {
    color: $color-roman;
  }

  @include focus {
    outline: none;
  }
}
