.documents-upload {
  width: 528px;
}

.documents-upload__buttons-wrapper {
  margin-top: 28px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;

  button {
    padding: 14px 32px 15px 32px;

    width: fit-content;
    margin-left: 16px;
    margin-top: 16px;
  }
}

.documents-upload__header {
  margin: 0;
  margin-bottom: 10px;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.documents-upload__text {
  margin: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: $color-grey-96;
}
