.link {
  @include reset-btn;
  text-align: left;
  color: $color-cornflower-blue;
  transition: color $default-transition-settings;
  font-weight: 500;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: #2566e8;
    }
  }
}

.link--grey {
  color: $color-grey-chateau;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: #2566e8;
    }
  }
}
