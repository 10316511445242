.modal-badges--select {
  .badges-item__price {
    display: none;
  }

  .badges-item {
    label {
      position: relative;
      cursor: pointer;

      &:hover {
        .badges-item__buttons {
          display: none;
        }
      }

      &::before {
        content: "";
        position: absolute;

        right: 0;
        top: 50%;
        transform: translateY(-50%);

        width: 20px;
        height: 20px;

        border-radius: 50%;
        border: 1px solid $color-hawkes-blue;
      }

      &::after {
        content: "";
        position: absolute;

        right: 6px;
        top: 50%;
        transform: translateY(-50%);

        width: 8px;
        height: 8px;

        transition: opacity $default-transition-settings;
        opacity: 0;
        border-radius: 50%;
        background-color: $color-black-primary;
      }
    }

    input {
      display: block;

      &:checked {
        & ~ label {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.modal-badges {
  .badges-list {
    max-height: calc(100vh - 350px);
    overflow-y: auto;
    padding-bottom: 32px;
    padding-right: 32px;
  }
}

.modal-badges__header {
  display: flex;
  justify-content: space-between;
  padding: 48px 32px 16px 32px;

  border-bottom: 1px solid $color-hawkes-blue;
}

.modal-badges__content {
  padding: 0;
  padding-left: 32px;
}

.modal-badges__text-wrapper {
  margin-right: 15px;
}

.modal-badges__title {
  margin: 0;
  margin-bottom: 5px;

  font-size: 20px;
  line-height: 24px;
}

.modal-badges__desc {
  margin: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.modal-badges__buttons-wrapper {
  padding-top: 25px;
  align-self: baseline;
  display: flex;

  button {
    margin-left: 12px;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
  }
}
