.catalog-info {
  form {
    max-width: 972px;
    padding: 24px 32px 100px 32px;
  }
}

.catalog-info__title {
  margin: 0;
  margin-bottom: 65px;

  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
}

.catalog-info__company-text {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 500;
}

.catalog-info__company-wrapper {
  display: flex;
  margin-left: -32px;
  padding-bottom: 25px;
}

.catalog-info__company {
  width: calc(50% - 32px);
  margin-left: 32px;
}

.catalog-info__inputs {
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
  margin-top: -24px;
  margin-bottom: 54px;

  .input-wrapper {
    width: calc(50% - 32px);
    margin-left: 32px;
    margin-top: 24px;
  }
}

.catalog-info__header {
  margin-bottom: 11px;
  display: flex;
  margin-left: -32px;

  p {
    margin: 0;
    width: calc(50% - 32px);
    margin-left: 32px;
    font-weight: 500;
  }
}

.catalog-info__checkbox-list {
  @include list-reset;

  li {
    display: flex;

    border-bottom: 1px solid $color-solitude-3;

    &:last-child {
      border: 0;
    }

    &.active {
      .checkbox-wrapper label,
      .catalog-info__eng {
        color: $color-black-primary;
      }
    }
  }
}
