.category-add {
  width: 672px;
  padding-top: 32px;
  padding-left: 32px;
  padding-bottom: 156px;

  .documents-upload {
    margin: 0;
    margin-bottom: 72px;
    width: 100%;

    .btn {
      width: 100%;
      margin-top: 43px;
    }
  }

  &--badges {
    .category-add__title {
      margin-bottom: 10px;
    }

    .documents-upload {
      margin-top: 67px;
    }
  }

  .table {
    margin-left: -33px;

    @media (min-width: 1440px) {
      width: calc(100% + (100vw - 1440px) / 2 + 33px);
    }
  }

  &--flex {
    width: unset;
  }
}

.category-add__title-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 66px;

  .category-add__title {
    margin: 0;
    margin-right: 10px;
  }

  .btn {
    position: absolute;
  }
}

.category-add__text-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.category-add__text {
  width: 70%;
}

.category-add__buttons {
  align-items: start;
  display: none;
  flex-wrap: wrap;

  button {
    margin-left: 12px;
  }

  &.active {
    display: flex;
  }
}

.category-add__title {
  margin: 0;
  margin-bottom: 66px;

  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
}

.category-add__desc {
  margin-bottom: 67px;
}

.category-add__text-title {
  margin: 0;
  margin-bottom: 5px;

  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.category-add__form {
  form {
    p {
      margin: 0;
    }

    h4 {
      margin: 0;
    }
  }
}

.category-add__list {
  @include list-reset;
}

.category-add__item {
  margin-bottom: 62px;

  &:last-child {
    margin-bottom: 0;
  }
}

.category-add__wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.add-item__inputs {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
  margin-top: -23px;

  .input-wrapper {
    width: calc(50% - 32px);
    margin-top: 23px;
    margin-left: 32px;
  }
}

.add-item__buttons-wrapper {
  display: flex;

  button {
    margin-left: 12px;
  }
}

.category-add__header {
  margin-bottom: 55px;

  &--badges {
    margin-bottom: 39px;

    .category-add__wrapper {
      flex-direction: column;
    }
  }
}

.category-add__container {
  width: 672px;
}

.category-add__badges-add {
  margin-top: 41px;
  justify-content: center;
  width: 100%;
  padding: 19px 32px 20px 24px;
  font-size: 16px;
  line-height: 20px;
}

.category-add__add {
  width: 100%;
  justify-content: center;
  margin-top: 72px;
}

.add-item {
  h4 {
    margin: 0;
    font-weight: 500;
  }
}

.add-item__wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;

  .btn {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
  }
}

.category-add__buttons-wrapper {
  display: flex;
  position: fixed;
  z-index: 2;
  bottom: 0;
  margin-left: -32px;
  width: 100%;
  border-top: 1px solid $color-solitude-3;

  @media (min-width: 1440px) {
    width: calc(100% + (100vw - 1440px) / 2);
  }

  .category-add__action-button {
    display: block;
  }

  &.active {
    width: calc(100vw - ((100vw - 1440px) / 2 + (25 * (1 / 100) * 1440px) + 24px));

    @media (max-width: 1440px) {
      width: calc(75% - 24px);
    }
  }
}

.category-add__buttons-desc {
  background-color: $color-default-white;
  border-right: 1px solid $color-solitude-3;
  width: 50%;
  text-align: center;
  padding: 14px;
}

.category-add__buttons-price {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.category-add__buttons-count {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  color: $color-grey-96;
}
