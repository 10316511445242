.stand-summary {
  padding: 48px 0;
}

.stand-summary__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stand-summary__dropdown {
  position: relative;

  &.active {
    .stand-summary__dropdown-button {
      border-radius: 8px 8px 0 0;

      svg {
        transform: rotate(-180deg);
      }
    }

    .stand-summary__dropdown-list {
      opacity: 1;
      visibility: visible;
    }
  }
}

.stand-summary__dropdown-button {
  padding: 16px 24px;
  border: 1px solid $color-hawkes-blue;
  background-color: $color-default-white;

  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  border-radius: 8px;

  transition: border-radius $default-transition-settings;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    margin-left: 9px;
    transition: transform $default-transition-settings;
  }
}

.stand-summary__dropdown-list {
  @include list-reset;
  position: absolute;
  z-index: 1;

  width: 100%;
  visibility: hidden;
  padding: 25px;

  opacity: 0;
  border: 1px solid $color-hawkes-blue;
  background-color: $color-default-white;
  border-top: 0;
  border-radius: 0 0 8px 8px;

  transition: opacity $default-transition-settings, visibility $default-transition-settings;
}

.stand-summary__dropdown-item {
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: block;
    font-size: 20px;
    line-height: 28px;
    color: $color-black-primary;
    transition: opacity $default-transition-settings;

    &:hover {
      opacity: 0.8;
    }
  }
}
