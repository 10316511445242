.input-wrapper {
  position: relative;

  label {
    position: relative;

    display: flex;
    width: 100%;
    transform: translateZ(0);
  }

  input[aria-invalid="true"]:not(.hack-selector) {
    border-bottom: 1px solid red;
    color: $color-roman;
  }

  input[aria-invalid="true"]:not(.hack-selector) + .input-wrapper__label {
    color: $color-roman;
  }

  input {
    width: 100%;
    height: 52px;
    padding: 16px 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $color-black-primary;

    background-color: $color-default-white;
    border: none;
    border-bottom: 1px solid $color-hawkes-blue;
    outline: none;

    transition: border-bottom $default-transition-settings;

    &:focus {
      border-bottom: 1px solid $color-black-primary;
    }

    &:focus[data-type="select"] {
      border-bottom: 1px solid $color-hawkes-blue;
    }

    &:focus + .input-wrapper__label {
      transform: translateY(-20px) scale(0.75);
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        border-bottom: 1px solid $color-black-primary;
      }
    }

    &:disabled {
      color: $color-grey-chateau;
      border-bottom: 1px solid $color-hawkes-blue;
    }

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 1000px $color-default-white;

      -webkit-text-fill-color: $color-black-primary;
    }
  }

  &__label {
    position: absolute;
    bottom: 17px;
    left: 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    color: $color-grey;
    pointer-events: none;
    transform-origin: left bottom;

    transition: transform $default-transition-settings;

    &.moved {
      transform: translateY(-20px) scale(0.75);
      font-weight: 400;
    }
  }

  &__error {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    color: red;
    position: absolute;
    left: 0;
    width: 100%;

    visibility: hidden;
    opacity: 0;

    transition: opacity $default-transition-settings, visibility $default-transition-settings;

    &.visible {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
}
