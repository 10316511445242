.simple-form {
  background: $color-default-white;
  border-radius: 6px;
  padding: 38px 32px 32px;
}

.simple-form__title {
  margin: 0 0 16px;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: $color-black-primary;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .link {
    margin-left: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.simple-form__caption {
  margin: 0 0 32px;
  font-size: 16px;
  line-height: 20px;
  color: $color-grey-chateau;
  font-weight: 500;
}

.simple-form .btn {
  width: 100%;
  justify-content: center;
  margin-top: 48px;
}

.simple-form__field {
  margin-bottom: 25px;
}

.simple-form__field--password {
  position: relative;

  .link {
    position: absolute;
    top: 13px;
    right: 0;
    z-index: 2;
  }

  input {
    padding-right: 140px;
  }
}
