.modal-inscription {
  padding: 48px 32px 36px 32px;

  .palette {
    margin-top: 40px;
  }

  &--colors {
    max-height: calc(100vh - 194px);
    max-height: calc(100 * var(--vh, 1vh) - 194px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}


.modal-inscription__title {
  margin: 0;
  margin-bottom: 4px;

  font-size: 20px;
  line-height: 24px;
}

.modal-inscription__desc {
  margin: 0;
  font-size: 12px;
  line-height: 16px;

  color: $color-grey-96;
  margin-bottom: 33px;
}
