.btn {
  position: relative;
  display: flex;
  align-items: center;
  padding: 19px 32px 20px 24px;
  width: fit-content;
  overflow: hidden;

  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  border: 0;
  color: $color-black-primary;
  background-color: $color-solitude-2;
  border-radius: 6px;
  transition: background-color $default-transition-settings;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  svg {
    fill: $color-black-primary;
    margin-right: 6px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-default-black;

    transition: opacity $default-transition-settings;

    opacity: 0;
  }

  @include hover {
    &::before {
      opacity: 0.07;
    }
  }

  &:focus {
    outline: none;
  }

  &--xl {
    font-size: 20px;
    line-height: 1.4;
  }

  &--wide {
    justify-content: center;
    width: 100%;
    padding: 22px 43px 23px;
  }

  &--thin {
    padding: 14px 32px 15px 24px;
  }

  &--extra-thin {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
  }

  &--fixed-size-100 {
    justify-content: center;
    width: 100px;
    padding: 9px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
  }

  &--no-txt {
    display: flex;
    height: 32px;
    padding: 0;

    svg {
      margin: auto 10px;
    }
  }

  &--light-txt {
    color: $color-grey-chateau;

    svg {
      fill: $color-grey-chateau;
    }
  }

  &--straight {
    border-radius: 0;
  }

  &--blue {
    background-color: $color-cornflower-blue;
    color: $color-default-white;

    svg {
      fill: $color-default-white;
    }
  }

  &--black {
    background-color: $color-black-primary;

    color: $color-default-white;
    transition: opacity $default-transition-settings;

    &::before {
      background-color: $color-default-white;
    }
  }

  &--red {
    background-color: $color-roman;

    color: $color-default-white;
    transition: opacity $default-transition-settings;
  }

  &--white {
    background-color: $color-default-white;

    color: $color-green;
    transition: opacity $default-transition-settings;
    border-radius: 0;
  }

  &--transparent {
    background-color: transparent;
    transition: opacity $default-transition-settings;
  }
}

.button-clear {
  border: 0;
  background-color: transparent;
  cursor: pointer;

  transition: opacity $default-transition-settings;

  @include hover {
    opacity: 0.7;
  }

  @include focus {
    outline: none;
  }
}
