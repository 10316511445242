.select {
  position: relative;

  &__wrap {
    position: relative;

    input {
      padding-right: 30px;
      cursor: pointer;
    }
  }

  &__dropdown-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    transition: fill 0.3s ease, transform 0.3s ease;

    pointer-events: none;
    z-index: 1;

    fill: #000000;
  }

  &__list {
    position: absolute;

    width: 100%;
    margin: 0;
    padding: 6px 0;

    list-style: none;

    background: $color-default-white;
    border-radius: 6px;
    border: 1px solid $color-hawkes-blue;
    border-top: none;
    visibility: hidden;
    opacity: 0;

    transition: opacity $default-transition-settings, visibility $default-transition-settings;
    z-index: 6;
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: auto;

    &--short {
      max-height: 150px;
    }

    &--extra-short {
      max-height: 90px;
    }
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $color-black-primary;
    background-color: $color-default-white;
    padding: 6px 24px;
    outline: none;
    cursor: pointer;
    transition: opacity $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }

    // &.active {
    //   font-weight: 700;
    // }
  }

  &.active {
    .select__list {
      visibility: visible;
      opacity: 1;
      border-radius: 0 0 6px 6px;
    }

    .select__dropdown-icon {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &--small {
    &.active {
      .js-select-input-wrapper {
        input {
          border-radius: 6px 6px 0 0;
        }
      }
    }

    .js-select-input-wrapper {
      position: relative;

      svg {
        pointer-events: none;
        position: absolute;
        right: 8px;
        top: 50%;

        transform: translateY(-50%);
      }

      input {
        width: 100%;
        padding: 8px 20px 6px 10px;
        display: block;
        border: 1px solid $color-solitude-3;
        border-radius: 6px;
        transition: border-radius $default-transition-settings;
        cursor: pointer;
        color: $color-black-primary;

        font-weight: inherit;
        font-size: 14px;
        font-feature-settings: "pnum" on, "lnum" on, "case" on;

        &:focus {
          outline: none;
        }
      }
    }

    .select__list {
      border-color: $color-solitude-3;
      border-top: 0;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        cursor: pointer;
        padding: 8px 20px 6px 10px;
        font-size: 14px;
        line-height: 14px;

        &:hover {
          background-color: $color-solitude-3;
        }

        &.active {
          background-color: $color-solitude-3;
        }
      }
    }
  }
}
