.footer {
  background-color: $color-black-primary;
  padding-top: 38px;
  padding-bottom: 32px;

  .container {
    display: flex;
    justify-content: space-between;
  }
}

.footer__logo-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 60px;

  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;

    font-feature-settings: "dnom" on;
    color: $color-grey-96;
    mix-blend-mode: normal;
  }
}

.footer__logo {
  position: relative;
  display: block;
  align-self: start;

  margin-bottom: 6px;
  transition: opacity $default-transition-settings;

  &::after {
    content: "®";
    position: absolute;
    right: -10px;
    top: -1px;

    font-weight: bold;
    font-size: 13px;
    line-height: 100%;

    color: $color-default-white;
  }

  @include hover {
    opacity: 0.7;
  }
}

.footer__copyright {
  margin-top: 0;
  margin-bottom: 6px;

  font-weight: bold;
  font-size: 20px;
  line-height: 20px;

  color: $color-default-white;
}

.footer__copyright-desc {
  margin: 0;
  font-size: 12px;
  line-height: 16px;

  color: $color-grey-96;
  text-align: right;

  a {
    color: inherit;
    transition: color $default-transition-settings;

    @include hover {
      color: $color-default-white;
    }
  }
}
