.section-grid {
  &--bordered {
    .section-grid__wrapper {
      border-top: 1px solid $color-solitude-3;
    }
  }
}

.section-grid__wrapper {
  display: flex;
  padding-right: 0;

  .container {
    display: flex;
    padding-right: 0;
  }
}

.section-grid__sidebar-list {
  @include list-reset;

  transition: opacity $default-transition-settings;

  &.hide {
    display: none;
  }

  &.no-opacity {
    opacity: 0;
  }
}

.section-grid--order {
  display: flex;
  flex-direction: column;

  .section-grid__wrapper {
    flex-grow: 1;
  }

  .section-grid__content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 222px);
    min-height: 400px;
    overflow: auto;

    .order-details {
      flex-grow: 1;
    }
  }

  .order-payment {
    height: calc(100vh - 222px);
    min-height: 400px;
    overflow: auto;
  }
}

.section-grid__sidebar-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &.active {
    a {
      color: $color-black-primary;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.section-grid__sidebar {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 32px;
  width: 25%;
  border-right: 1px solid $color-solitude-3;

  .section-grid__sidebar-open {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .aside__content {
    top: 0;
    padding: 32px 0;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .section-grid__sidebar-sections {
    @include list-reset;

    li {
      margin-bottom: 2px;

      font-size: 16px;
      line-height: 20px;

      a {
        width: 100%;
        padding-left: 32px;
        padding-top: 4px;
        padding-bottom: 6px;
        display: block;
        border-radius: 4px;

        font-weight: 500;

        transition: background-color $default-transition-settings;

        &:hover {
          background-color: $color-solitude-2;
        }
      }

      &.active {
        a {
          background-color: $color-solitude-2;
        }
      }
    }
  }

  p {
    margin: 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: $color-grey-96;
  }
}

.section-grid__sidebar-text-wrapper {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.section-grid__sidebar-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: $color-grey-96;
  transition: color $default-transition-settings;

  &:hover {
    color: $color-black-primary;
  }
}

.section-grid__sidebar-link-wrapper {
  max-width: 85%;
  line-height: 20px;
}

.section-grid__sidebar-number {
  font-feature-settings: "tnum" on, "lnum" on;
}

.section-grid__content {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  width: 75%;

  .documents-upload {
    margin-top: 32px;
    margin-bottom: 47px;
    margin-left: 32px;
  }
}
