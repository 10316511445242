.account-chats {
  padding: 48px 0;
  border-top: 1px solid $color-solitude-3;
}

.account-chats__list {
  width: 672px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.account-chats__item:not(:last-of-type) {
  margin-bottom: 16px;
}
