.cart {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;

  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  transition: opacity $modal-transition-settings,
    visibility $modal-transition-settings;

  &--opened {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

.cart__overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.cart__order-counter {
  margin-left: 32px;
}

.cart__content {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 12;
  width: 500px;
  max-width: 100vw;
  transform: translateX(100%);
  background-color: $color-default-white;
  border-left: 1px solid $color-solitude-3;
  transition: transform $modal-transition-settings;

  .cart--opened & {
    transform: translateX(0);
  }
}

.cart__total {
  margin: 0;
  padding: 34px 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  color: $color-grey-chateau;
  border-bottom: 1px solid $color-solitude-3;

  span {
    color: $color-black-primary;
  }
}

.cart__wrapper {
  flex-shrink: 1;
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cart__actions {
  display: flex;
  opacity: 0;
  visibility: hidden;

  transition: opacity $default-transition-settings, visibility $default-transition-settings;

  .btn {
    margin-left: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    padding: 6px 10px;

    svg {
      margin: 0;
      fill: $color-grey-chateau;
    }
  }
}

.cart__services {
  margin: 32px;
  margin-top: 0;
  padding: 0;
  padding-bottom: 0;
  list-style: none;
}

.cart__service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid $color-solitude-3;

  &:hover {
    .cart__actions {
      opacity: 1;
      visibility: visible;
    }
  }
}

.cart__card {
  width: 320px;
}

.cart__btn {
  margin-top: auto;
}

.cart__close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  outline: none;

  svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: $color-default-black;
    transition: fill $default-transition-settings;
  }

  &::after {
    content: "";
    position: absolute;
    top: -8px;
    right: -8px;
    width: 32px;
    height: 32px;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-grey;
    }
  }
}
