.stand-card {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.25;
  color: $color-black-primary;
  background-color: $color-default-white;
  border-radius: 6px;
  overflow: hidden;

  &--simple {
    align-items: stretch;
    padding: 7px 0;
    border-top: 1px solid $color-solitude-3;
    border-bottom: 1px solid $color-solitude-3;
    border-radius: 0;
  }

  &--modal {
    flex-direction: column;
  }
}

.stand-card__photo {
  flex-shrink: 0;
  display: block;
  width: 40%;
  height: auto;
  background-color: $color-hawkes-blue;

  .stand-card--simple & {
    width: 207px;
    height: 138px;
    border-radius: 6px;
  }

  .stand-card--modal & {
    width: 100%;
    height: 200px;
    margin-bottom: 22px;
    border-radius: 6px;
    object-fit: contain;
  }
}

.stand-card__desc {
  width: 60%;
  padding: 20px 32px;

  .stand-card--simple & {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-left: 16px;
  }

  .stand-card--modal & {
    width: 100%;
    padding: 0;
  }
}

.stand-card__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  border-top: 1px solid $color-solitude-2;

  &:first-of-type {
    padding: 16px 0;
    border-top: none;

    .stand-card--simple & {
      margin-bottom: 2px;
      padding: 0;
      padding-top: 14px;
    }
  }

  .stand-card--modal & {
    align-items: flex-start;
    padding: 0;
    padding-bottom: 27px;
    border-top: none;

    &:not(:last-of-type) {
      margin-bottom: 22px;
    }
  }
}

.stand-card__title {
  margin: 0;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.11;

  .stand-card--simple & {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
  }
}

.stand-card__base-price {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;

  .stand-card--modal & {
    margin-right: auto;
    font-weight: 500;
  }
}

.stand-card__included {
  max-width: 570px;
  margin: 0;
  margin-right: -32px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  .stand-card--modal & {
    flex-direction: column;
    width: 345px;
    margin-right: 0;
  }
}

.stand-card__included-item {
  width: 50%;
  padding-right: 32px;

  &::before {
    content: " •  ";
  }

  .stand-card--modal & {
    width: auto;
    margin-right: 0;
  }
}

.stand-card__sizes {
  margin: 0;

  .stand-card--simple & {
    padding-bottom: 14px;
    font-size: 14px;
    line-height: 1.29;
    color: $color-grey-chateau;
  }
}

.stand-card__wrapper-title {
  .stand-card--modal & {
    width: 185px;
    margin: 0;
    padding-right: 25px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
  }
}

.stand-card__about {
  margin: 0;

  .stand-card--modal & {
    width: 345px;
    font-size: 16px;
    line-height: 1.25;
  }
}

.stand-card__controls {
  .stand-card--simple & {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    margin-bottom: -6px;

    a {
      margin-right: 12px;
      margin-bottom: 6px;
    }
  }
}

.stand-card__header {
  .stand-card--modal & {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 14px;

    & input {
      margin-right: 8px;
    }
  }
}

.stand-card__stand-type {
  .stand-card--modal & {
    width: 185px;
    margin: 0;
    padding-right: 25px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
  }
}
