.account-chat__history {
  padding: 48px 0 125px;
  border-top: 1px solid $color-solitude-3;
}

.account-chat__messages {
  width: 960px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.account-chat__message-series {
  margin-bottom: 16px;
}

.account-chat__message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;

  .account-chat__message-series--me & {
    flex-direction: row-reverse;
  }

  .account-chat__message-series--they & {
    flex-direction: row;
  }
}

.account-chat__message-time {
  flex-grow: 0;
  flex-shrink: 0;
  width: 120px;
  margin: 0 12px;
  font-size: 12px;
  line-height: 1.33;
  color: $color-grey-chateau;

  .account-chat__message-series--me & {
    text-align: left;
  }

  .account-chat__message-series--they & {
    text-align: right;
  }
}

.account-chat__message-content {
  width: fit-content;
  max-width: 555px;

  .account-chat__message--complex & {
    width: min-content;
  }
}

.account-chat__message-text {
  margin: 0;
  padding: 14px 24px 15px;
  border: 1px solid $color-solitude-3;
  border-radius: 12px;

  .account-chat__message-series--me & {
    background-color: $color-solitude-2;
  }

  .account-chat__message-series--they & {
    background-color: $color-default-white;
  }

  .account-chat__message--complex & {
    border-radius: 0;
    border-top: none;
    border-bottom: none;

    &:first-child {
      border-radius: 12px 12px 0 0;
      border: 1px solid $color-solitude-3;
      border-bottom: none;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
      border: 1px solid $color-solitude-3;
      border-top: none;
    }
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.account-chat__message-image {
  max-width: 320px;
  height: auto;
  border-radius: 12px;

  .account-chat__message--complex & {
    max-width: 555px;
    border-radius: 0;

    &:first-child {
      border-radius: 12px 12px 0 0;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
    }
  }
}

.account-chat__send-form {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 22px 0;
  background-color: $color-default-white;
  border-top: 1px solid $color-solitude-3;

  form {
    display: flex;
    width: 684px;
    margin: 0 auto;
  }

  input {
    flex-grow: 1;
    margin: 0 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    color: inherit;
    border: none;
    outline: none;
  }
}

.account-chat__upload {
  display: flex;
  align-items: center;
  padding: 14px 24px 15px;
  border: 1px solid $color-solitude-3;
  border-radius: 12px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .account-chat__message--complex & {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    border-top: none;
    border-bottom: none;

    &:first-child {
      padding-top: 14px;
      padding-bottom: 0;
      border-radius: 12px 12px 0 0;
      border: 1px solid $color-solitude-3;
      border-bottom: none;
    }

    &:last-child {
      padding-top: 0;
      padding-bottom: 15px;
      border-radius: 0 0 12px 12px;
      border: 1px solid $color-solitude-3;
      border-top: none;
    }
  }
}

.account-chat__icon-link {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;
  width: 64px;
  height: 64px;
  margin-right: 12px;
  padding: 16px;
  background-color: $color-hawkes-blue;
  border-radius: 6px;

  &:hover,
  &:focus {
    opacity: 0.6;
  }
}

.account-chat__icon {
  width: 32px;
  height: 32px;
  padding: 6px;
  background-color: rgba($color-default-black, 0.5);
  border-radius: 50%;

  svg {
    display: block;
    fill: $color-default-white;
  }
}

.account-chat__upload-filename {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}

.account-chat__upload-filemeta {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
}

.account-chat__upload-pseudolink {
  color: $color-cornflower-blue;
}
