.add-contractor__wrapper {
  padding-bottom: 74px;
  overflow: hidden;
}

.add-contractor__personal {
  margin: 48px 32px 30px;
}

.add-contractor__personal-title {
  margin: 0;
  margin-bottom: 48px;
  font-size: 20px;
  line-height: 24px;
}

.add-contractor__fieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border: none;
}

.add-contractor__input {
  width: calc(50% - 16px);
  margin-bottom: 24px;
}

.add-contractor__services-title {
  margin: 0 32px 7px;
  font-size: 20px;
  line-height: 1.2;
}

.add-contractor__services-desc {
  margin: 0 32px 44px;
  font-size: 16px;
  line-height: 1.25;
  color: $color-grey-96;
}

.add-contractor__group {
  margin: 0 32px 44px;

  label {
    display: block;
    position: relative;
    width: fit-content;
    padding-left: 28px;
    font-size: 16px;
    line-height: 1.25;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
    }

    &::before {
      border: 1px solid $color-hawkes-blue;
      border-radius: 3px;
    }

    &:hover::before,
    &:focus::before {
      border-color: $color-default-black;
    }
  }

  input:focus + label::before {
    border-color: $color-default-black;
  }

  input:checked + label::after {
    background: url("../img/svg/icon-checked.svg") center no-repeat;
  }
}

.add-contractor__group-wrapper {
  width: calc(50% - 16px);
  margin-top: 16px;
}

.add-contractor__group-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid $color-hawkes-blue;
}

.add-contractor__group-title {
  margin: 0;
  font-size: 16px;
  line-height: 1.25;
}

.add-contractor__btn-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid $color-hawkes-blue;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}
