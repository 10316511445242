.contact-us {
  padding: 99px 0 80px;
}

.contact-us__title {
  max-width: 600px;
  margin: 0 auto 21px;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: $color-black-3;
  text-align: center;
}

.contact-us__contacts {
  list-style: none;
  padding: 0;
  margin: 0 auto 70px;
  text-align: center;
  max-width: 600px;

  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.contact-us__contact-link {
  transition: color $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: rgba($color: $color-default-black, $alpha: 0.7);
    }
  }
}

.contact-us__form {
  max-width: 438px;
  margin: 0 auto;
}

.contact-us__form .input-wrapper {
  margin-bottom: 24px;
}

.contact-us__fields {
  margin-bottom: 48px;
}

.contact-us__form .btn {
  width: 100%;
  justify-content: center;
}
