.notification {
  display: flex;
  transform: translateX(480px);
  cursor: pointer;
  transition: transform $default-transition-settings, height $default-transition-settings;

  &.active {
    transform: translateX(0);

    .notification__wrapper {
      margin-bottom: 24px;
      padding: 16px 65px 20px 24px;
    }
  }

  &.remove {
    transform: translateX(480px);
  }

  @include hover {
    .notification__close-btn {
      opacity: 1;
      visibility: visible;
    }

    .notification__time {
      display: none;
    }
  }

  &--ff {
    position: fixed;
    right: 24px;
    bottom: 0;
  }
}

.notification__wrapper {
  padding: 16px 65px 20px 24px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 438px;
  background-color: $color-default-white;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(black, 0.1);
}

.notification__content {
  flex-grow: 1;
}

.notification__tooltip {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.33;
  color: $color-green;

  .notification--tooltip-accent & {
    color: $color-roman;
  }
}

.notification__message {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}

.notification__message-additional {
  color: $color-grey-chateau;
  margin-left: 4px;
}

.notification__time {
  position: absolute;
  right: 24px;
  top: 16px;
  flex-shrink: 0;
  display: block;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.33;
  color: $color-grey-chateau;
}

.notification__close-btn {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  padding: 10px;

  opacity: 0;
  visibility: hidden;
  background-color: $color-solitude-2;
  border-radius: 50%;
  outline: none;

  svg {
    display: block;
    width: 12px;
    height: 12px;
    fill: $color-default-black;
    transition: fill $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-grey;
    }
  }
}
