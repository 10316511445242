.account-orders {
  margin: 0;
  margin-bottom: 195px;
  padding: 0;
  list-style: none;
}

.account-orders__item {
  margin-right: 32px;
  padding: 0;
  border-bottom: 1px solid $color-solitude-3;
}
