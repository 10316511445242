.content-info {
  border-top: 1px solid $color-solitude-3;

  .container {
    display: flex;
  }

  .content-block {
    max-width: 790px;
    padding: 70px 0 80px 30px;
  }

  &--agreement {
    .counter-item {
      margin-bottom: 16px;

      color: $color-grey-96;

      @include hover {
        color: $color-black-primary;
      }

      a {
        color: inherit;
      }
    }

    .tab__items {
      position: sticky;
      top: 0;
    }
  }
}
