.category {
  padding-top: 48px;
  padding-left: 32px;
  padding-right: 32px;
  flex-grow: 1;
  width: 100%;
  background-color: $color-solitude-2;

  .search-form {
    margin-bottom: 46px;

    button {
      display: none;
    }

    input {
      width: 100%;
    }
  }
}

.category__section {
  padding-top: 32px;
  padding-bottom: 48px;

  border-top: 1px solid $color-default-white;
}

.category__section-title {
  margin: 0;
  margin-bottom: 32px;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
