.form-footer {
  display: flex;
  margin-left: -33px;

  .form-footer__button {
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-radius: 0;

    &--cart {
      border: 0;
      background-color: $color-cornflower-blue;
      color: $color-default-white;
    }

    &--service {
      border: 1px solid $color-hawkes-blue;
      border-right: 0;
      border-top: 0;
      background-color: $color-default-white;
      color: $color-cornflower-blue;
    }
  }
}
