.input-file {
  width: 436px;
  justify-content: center;
  margin-top: 29px;
  cursor: pointer;

  .btn {
    width: 100%;
    justify-content: center;
  }
}
