.modal-edit-info__title {
  margin: 32px;
  margin-bottom: 48px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
}

.modal-edit-info__inputs {
  padding: 0 32px 36px;
  border-bottom: 1px solid $color-hawkes-blue;
}

.modal-edit-info__input {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
