.main-nav {
  margin-left: 60px;
}

.main-nav__toggle {
  display: none;
}

.main-nav__link {
  position: relative;

  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  color: $color-black-primary;
  transition: opacity $default-transition-settings;

  svg {
    margin-right: 7px;
    transform: translateZ(0);
  }

  @include hover {
    opacity: 0.7;
  }

  &--active {
    @include hover {
      opacity: 1;
    }

    span {
      color: $color-grey;
    }
  }
}


.main-nav__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-top: -20px;
  margin-right: -48px;
  padding: 0;

  list-style: none;
}

.main-nav__item {
  position: relative;
  z-index: 2;
  margin-right: 48px;
  margin-top: 20px;

  &.active {
    .dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
}

.main-nav__link--cart {
  &.empty {
    .main-nav__counter {
      display: none;
    }
  }
}

.main-nav__counter {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(10%);

  display: flex;
  align-items: center;
  height: 15px;
  padding: 0 3px;

  font-size: 10px;
  line-height: 15px;

  color: $color-default-white;
  background-color: $color-grey;
  border-radius: 7.5px;
  border: 1px solid $color-default-white;
}
