.lamination-color {
  padding: 9px 12px;

  label {
    position: relative;
    display: block;
    width: 43px;
    height: 43px;
    background: url("../img/bg/colors-pattern.png");
    border-radius: 50%;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      width: 53px;
      height: 53px;
      border-radius: 50%;
    }

    &:hover::before {
      border: 1px solid $color-hawkes-blue;
    }
  }

  input:checked + label::before {
    border: 1px solid $color-cornflower-blue;
  }
}
