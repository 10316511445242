.order-card {
  margin-right: -16px;
  padding-right: 16px;
  background-color: $color-default-white;
  overflow: hidden;
}

.order-card__header {
  display: flex;
  align-items: center;
  margin: 12px 16px;
  margin-right: -16px;
  padding: 12px 16px;
  padding-left: 16px;
  border-radius: 6px;
  outline: none;
  transition: background-color $default-transition-settings;

  svg {
    display: block;
    margin-right: 3px;
    width: 16px;
    height: 16px;
    fill: $color-black-primary;
    transform: rotate(180deg);
    transition: transform $default-transition-settings;
  }

  &.active {
    color: $color-grey-chateau;

    svg {
      fill: $color-grey-chateau;
      transform: rotate(0);
    }
  }

  &:hover,
  &:focus {
    background-color: $color-solitude-4;
  }
}

.order-card__order-id {
  width: 165px;
  margin: 0;
  margin-right: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
}

.order-card__status {
  width: 215px;
  margin: 0;
  margin-right: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
}

.order-card__date {
  display: block;
  width: 215px;
  margin: 0;
  margin-right: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
}

.order-card__total-price {
  display: block;
  width: 165px;
  margin: 0;
  margin-left: auto;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  text-align: right;
}

.order-card__details {
  display: block;
  padding-left: 32px;

  max-height: 0;
  overflow: hidden;
  transition: max-height $default-transition-settings;

  &.active {
    overflow: visible;
  }
}

.order-card__records {
  width: 100%;
  margin-bottom: 8px;
  border-collapse: collapse;
}

.order-card__record {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;

  .order-card__file {
    display: none;
  }

  &:hover {
    .order-card__file {
      display: inline;
    }
  }

  &--extra {
    color: $color-grey-chateau;
  }

  td {
    padding-bottom: 24px;

    &:not(:first-of-type) {
      width: 205px;
      text-align: right;
    }
  }
}

.order-card__controls {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-right: -8px;
  margin-bottom: 24px;
  padding: 0;
  list-style: none;
}

.order-card__control {
  padding-right: 8px;
  padding-bottom: 8px;
}
