.modal-lamination {
  padding-bottom: 74px;
}

.modal-lamination__title {
  margin: 48px 32px;
  font-size: 20px;
  line-height: 1.2;
}

.modal-lamination__inputs {
  padding: 0 32px 36px;
}

.modal-lamination__input {
  margin-bottom: 40px;
}

.modal-lamination__btn-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $color-default-white;
  border-top: 1px solid $color-hawkes-blue;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}
