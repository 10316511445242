.event-card {
  display: flex;
  position: relative;
}

.event-card__link {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 362px;
  padding: 24px 0 28px;
  color: $color-black-primary;
  background-color: $color-default-white;
  border-radius: 6px;
  transition:
    transform $default-transition-settings,
    box-shadow $default-transition-settings;

  &--archived {
    opacity: 0.85;
  }
}

.event-card__wrapper {
  max-width: 100%;
  margin: 24px;
  margin-top: 0;
  margin-right: 64px;
}

.event-card__logo {
  display: block;
  width: 210px;
  max-width: 100%;
  height: auto;
}

.event-card__date {
  display: block;
  margin-bottom: 24px;
  padding: 9px 24px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;
  border-top: 1px solid $color-solitude-4;
  border-bottom: 1px solid $color-solitude-4;
}

.event-card__title {
  margin: 0 24px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}

.event-card__desc {
  margin: 0 24px 12px;
  font-size: 14px;
  line-height: 1.29;
}

.event-card__place {
  margin: auto 24px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;
}

.event-card__location {
  margin: 0 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;
}

.event-card__actions {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 44px;
  height: 44px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: opacity $default-transition-settings;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    transform: translate(-50%, -50%);
    background-color: $color-nero;
    border-radius: 50%;
    box-shadow:
      -7px 0 0 $color-nero,
      7px 0 0 $color-nero;
  }

  &:hover:not(:disabled),
  &:focus {
    opacity: 0.6;
  }

  &:disabled {
    display: none;
  }
}

.event-card__popup {
  position: absolute;
  z-index: 1;
  top: 40px;
  right: 32px;
  transform: translateX(50%);
  width: 227px;
  font-size: 16px;
  line-height: 1.25;
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  transition: opacity $default-transition-settings,
    visibility $default-transition-settings;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    z-index: 1;
    width: 16px;
    height: 6px;
    transform: translateX(-50%);
    background-image: url("../img/svg/wave-grey.svg");
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 1.25;
  }

  svg {
    display: block;
    fill: $color-black-primary;
  }

  &--opened {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  @include viewport--xxl {
    transform: translateX(20%);

    &:before {
      left: 80%;
    }
  }
}

.event-card__popup-content {
  background-color: $color-default-white;
  border: 1px solid $color-hawkes-blue;
  border-radius: 6px;
  overflow: hidden;
}

.event-card__popup-selected-stand {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
  background-color: $color-solitude-2;
  border-bottom: 1px solid $color-hawkes-blue;

  span {
    display: block;
  }

  svg {
    flex-shrink: 0;
    display: block;
    width: 20px;
    height: 20px;
    transition: transform $default-transition-settings;

    .event-card__popup--opened-actions & {
      transform: rotate(180deg);
    }
  }
}

.event-card__popup-wrapper {
  position: relative;
  height: 164px;
}

.event-card__popup-stands {
  margin: 0;
  padding: 18px;
  list-style: none;
  overflow-y: auto;
}

.event-card__popup-link {
  display: block;
  padding: 6px;
  color: $color-black-primary;
  transition: color $default-transition-settings;

  &:hover,
  &:focus {
    color: $color-grey-chateau;
  }
}

.event-card__stand-actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  width: 100%;
  margin: 0;
  padding: 18px;
  list-style: none;
  background-color: $color-default-white;
  transition: transform $default-transition-settings;

  .event-card__popup--opened-actions & {
    transform: translateY(0);
  }
}
