.upgrade-stand {
  margin-bottom: 0;

  &--stand-selected {
    margin-bottom: 74px;
  }
}

.upgrade-stand__list {
  margin: 0;
  padding: 0 32px 22px;
  list-style: none;
  transition: margin-bottom $default-transition-settings;
}

.upgrade-stand__item {
  padding-top: 52px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $color-solitude-3;
  }
}

.stand-card__btn-wrapper {
  display: none;
  border-radius: 0 0 6px 6px;
  overflow: hidden;

  .upgrade-stand--stand-selected & {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid $color-hawkes-blue;
  }
}
