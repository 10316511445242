.section-navigation {
  border-top: 1px solid $color-solitude-3;
  border-bottom: 1px solid $color-solitude-3;
}

.section-navigation__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 0;
  list-style: none;
}

.section-navigation__item {
  padding: 0 20px;

  &.active {
    .section-navigation__link {
      margin-top: -1px;
      color: $color-black-primary;
      border-top: 1px solid $color-default-black;

      @include hover {
        opacity: 1;
      }
    }
  }
}

.section-navigation__link {
  display: block;
  padding: 15px 0;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  color: $color-grey-chateau;
  transition: opacity $default-transition-settings;

  @include hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.6;
  }
}
