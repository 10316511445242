.catalog {
  padding: 80px 0;
  color: $color-black-primary;
}

.catalog__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 45px;
  border-bottom: 1px solid $color-hawkes-blue;
}

.catalog__title {
  margin: 0;
  font-weight: 700;
  font-size: 36px;
  line-height: 1.11;
}

.catalog__menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  padding: 0;
  padding-top: 15px;
  list-style: none;
}

.catalog__menu-item {
  display: flex;
  width: 33.3333%;
  padding: 0 16px;
}

.catalog__menu-link {
  flex-grow: 1;
  display: block;
  min-height: 110px;
  padding: 12px 0;
  color: $color-black-primary;
  border-bottom: 1px solid $color-hawkes-blue;
  transition: border-color $default-transition-settings;

  @include hover {
    padding-bottom: 11px;
    border-width: 2px;
    border-color: $color-default-black;
  }
}

.catalog__item-title {
  margin: 0;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
}

.catalog__item-features {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.catalog__item-feature {
  margin-right: 12px;
  font-size: 14px;
  line-height: 1.29;
  color: $color-grey-96;
}
