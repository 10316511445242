.pwd-change {
  background: $color-solitude-2;
  padding: 40px 32px 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pwd-change__popup {
  width: 100%;
  max-width: 502px;
}
