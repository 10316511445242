.chat-card {
  position: relative;
  border-radius: 6px;

  &--chat-header {
    display: flex;
    align-items: center;
    width: 672px;
    background-color: transparent;
  }
}

.chat-card__label {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  transition: background-color $default-transition-settings;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: rgba($color-solitude-2, 0.4);
  }

  .chat-card--select-mode & {
    display: block;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &::before {
    right: -28px;
    width: 20px;
    height: 20px;
    border: 1px solid $color-hawkes-blue;
  }

  input:checked + &::after {
    right: -22px;
    width: 8px;
    height: 8px;
    background-color: $color-black-primary;
  }
}

.chat-card__link {
  display: flex;
  width: 100%;
  min-height: 122px;
  background-color: $color-solitude-2;
  border-radius: 6px;
  transition: opacity $default-transition-settings;

  &:hover,
  &:focus {
    opacity: 0.6;
  }
}

.chat-card__avatar__wrapper {
  flex-shrink: 0;
  margin: 20px;

  .chat-card--chat-header & {
    margin: 0;
    margin-right: 16px;
  }
}

.chat-card__avatar {
  display: block;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  object-fit: cover;

  .chat-card--chat-header & {
    width: 64px;
    height: 64px;
  }
}

.chat-card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 20px;
  margin-top: 17px;
  margin-left: 0;
}

.chat-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 1.33;
  color: $color-grey-chateau;

  .chat-card--chat-header & {
    display: block;
  }
}

.chat-card__cathegory {
  margin: 0;

  .chat-card--chat-header & {
    font-size: 16px;
    line-height: 1.25;
  }

  &--important {
    color: $color-roman;
  }
}

.chat-card__time {
  display: block;
  flex-shrink: 0;
  margin-left: 20px;
  color: $color-mischka;
}

.chat-card__title {
  width: 530px;
  height: 20px;
  margin: 0;
  margin-bottom: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  color: $color-default-black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.chat-card__lead {
  margin: 0;
  font-size: 16px;
  line-height: 1.25;
  color: $color-default-black;
  /* stylelint-disable */
  display: -webkit-box;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* stylelint-enable */
  -webkit-line-clamp: (2);
  max-height: (20px * 2);
}

.chat-card__messages {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  margin-left: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 2;
  color: $color-default-white;
  text-align: center;
  background-color: $color-black-primary;
  border-radius: 50%;
}
